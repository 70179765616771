.nav-profile {
    width: 14.5rem;

    .nav-profile-header {
        padding: 0.7rem 1rem;
        border-bottom: 1px solid $border-color;
    }

    .nav-profile-body {
        padding-top: 25px;
        padding: 0.625rem 0;

        .ant-menu-vertical > .ant-menu-item {
            height: 2.1875rem;
            line-height: 2.1875rem;
        }
    }
}